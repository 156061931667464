.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0 0;
  background: #EFEFEF;
}

.container2 {
  padding: 20px 20px 0 0;
  background: #EFEFEF;
}

.search_input {
  width: 55%;
}

.list_nav {
  display: flex;
}

.list_nav li {
  user-select: none;
  color: #757575;
  display: flex;
  align-items: center;
  gap: .5rem;
  font-weight: bold;
}

.list_nav li:last-child {
  color: #000;
}

.list_nav li svg {
  width: 20px;
}

.list_nav svg:hover {
  color: #2b2b2b;
}