.graphicContent{
    display: flex;
    flex-direction: column;
}

.textContainer{
    display: flex;
    text-align: justify;
    padding: 0 10px;
    flex-direction: column;
    align-items: flex-start;
}

.graphic{
    width: 35%;
    /* margin: 22% 0 0 0; */
    align-self: flex-end;
}

.graphicGroup{
    width: 70%;
}


.texts{
    display: flex;
    flex-direction: column;
    text-align: left;
}

.texts p{
    margin: 10px 5px 0;
}

.texts strong{
    font-size: 2rem;
    margin: 0 0.3rem 0 0;
}

.subtext{
    color: #8c8c8d;
}

.percentGraph{
    margin-top: -2px ;
    font-size: 1.7rem ;
    margin-left: 6%;
}

.title{
    font-size: 2rem;
    margin: 0;
    padding: 5px;
}

.chart{
    width: 32%;
    border-radius: 10px;
    box-shadow: -9px 10px 15px #a9a9a98f;
}

.new_chart{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-width: 90px;
}

.new_chart h1{
    font-size: .9rem;
}

@media (max-width: 1025px){
    .percentGraph{
        font-size: 1rem;
    }
}

@media (max-width: 981px){
    .title{
        font-size: 1rem;
        gap: 8px;
    }
}

@media (max-width: 681px){
    .chart{
        width: 100%;
    }
}