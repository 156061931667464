
.card_percent{
    border-radius: 10px;
    display: flex;
    background: linear-gradient(0deg, #1C2973 0%,  #5065DA 100%);
    flex-direction: column;
    width: 100px;
    gap: .5rem;
    padding: 5px 15px;
    align-items: center;
}

.card_percent h1{
    margin: 0;
    color: #fff;
}

.left header{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.left{
    width: 65%;
}


.main hr{
    margin: 15px;
}

.main{
    display: flex;
}

.topology_and_services{
    padding: 10px 0;
}

.topology_and_services hr{
    margin: 0px;
}

.header h1, .header p{
    margin: 0;
}



.header{
    display: flex;
    justify-content: space-between;
    font-size: .87rem;
    align-items: center;
}

.header div{
    padding: 4px 5px;
    border-radius: 20px;
    background-color: #00BA13;
    color: #fff;
    display: flex;
    gap: .5rem;
    align-items: center;
}

.p_circle{
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #fff;
}

.services{
    display: flex;
    flex-direction: column;
    gap: .2rem;
}

.span_services{
    display: flex;
    justify-content: space-between;
    gap: .5rem;
    height: 100%;
    align-items: center;

}

.icons_services, .icons_services_desactivated, .icons_services_down{
    background-color: #00BA13;
    padding: 5px 10px;
    border-radius: 8px;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 3em;

}

.icons_services_desactivated{
    background-color: #D9D9D9;
    color: #A6A6A6;
}

.icons_services_down{
    background-color: #d71a1a;
    color: #fff;
}

.icons_services p, .icons_services_desactivated p, .icons_services_down p{
    margin: 5px;
}

.topology_and_services{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.topology{
    width: 50%;
}

.topology .header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1px 5px;
}

.header h1{
    margin: 5px 0;
}

.topology .header hr{
    width: 100%;
    margin: 4px;
}

.span_icon p, .span_info hr, .span_info p{
    margin: 0;
}

.span_info hr{
    width: 100%;
}

.topology_content{
    display: flex;
    justify-content: center;
    gap: .5rem;
    align-items: center;
}

.span_icon, .span_info{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2px 0;
}

.span_info .color{
    color: #00BA13;
    font-weight: bold;
    font-size: .8rem;
    
}

.span_info{
    font-size: .9rem;
}

.header_eth h1, .header_eth hr{
    margin: 0;
}

.header_eth h1{
    font-size: .9rem;
}

.content_hgu_info{
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-evenly;
}

.ports_info h1, .ports_info p{
    margin: 0;
    font-size: .8rem;
}

.ports_info li{
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
}

.ports_info li div span{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.ports_info{
    padding-left: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 5px;
}

.ports_info li span svg{
    width: 20px;

}

.h1_port {
    color: #00BA13;
}

.h1_port_down{
    color: #FF0000;
}

.items_hgu, .items_hgu_color{
    display: flex;
    justify-content: space-between;
    padding: 0px 4px;
    align-items: center;
    gap: .5rem;
}

.items_hgu h1, .items_hgu p, .items_hgu_color h1, .items_hgu_color p{
    font-size: .8rem;
    margin: 0;
}

.items_hgu_color{
    background-color: #a6a6a65e;
}

.items_hgu svg, .items_hgu_color svg{
    width: 20px;
}

.right header svg{
    color: #5065DA;
}

.right div{
    padding: 0 5px;
    border: 1px solid #5065DA;
    border-radius: 8px;
}

.right article{
    display: flex;
    flex-direction: column;
    gap: .2rem;
}

.right{
    display: flex;
    gap: .5rem;
    justify-content: 35%;

}