.container{
    display: flex;
    justify-content: space-evenly;
}

.container section{
    width: 35%;
    overflow-y: auto;
}

.container section h1{
    margin: 10px 0;
}

.container img{
    margin: 50px 0;
    height: 150px;
}

.div_info{
    display: flex;
    padding: 5px 10px;
    gap: 1rem;
    background-color: #c9c9cc54;
    margin: 10px 0;
}

.div_info ul{
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin: 0;
}

.div_info p{
    margin: 0;
    font-size: .8rem;
}

.title{
    font-weight: bold;
}

.div_icon{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.container_devices{
    overflow-y: auto;
    height: 55dvh;
}

.container_devices::-webkit-scrollbar {
    width: 10px;          /* width of the entire scrollbar */
}

.container_devices::-webkit-scrollbar-track {
background: transparent;        /* color of the tracking area */
}

.container_devices::-webkit-scrollbar-thumb {
background-color: rgb(161, 161, 161);   /* color of the scroll thumb */
border-radius: 20px;       /* roundness of the scroll thumb */
}