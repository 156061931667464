
.main{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.passed, .failed{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.passed h1, .failed h1{
    font-size: 1rem;
}

.passed h1, .failed h1 ,.failed p, .passed p{
    margin: 0;
}

.container_p{
    display: flex;
    gap: .3rem;
}

.container_p p{
    width: 6px;
    height: 30px;
    background-color: #D9D9D9;
}

.passed .container_p .bar_i_passed{
    background-color: #00BA13;
}

.failed .container_p .bar_i_failed_colored{
    background-color: #FF0000;
}