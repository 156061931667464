.container_top{
    display: flex;
    width: 98%;
    height: 600px;
    padding: 10px;
    justify-content: space-between;
}

.graphics_hosts{
    width: 70%;
    height: 195px;
}

.hgu_info{
    width: 28%;
    height: 608px;
}

.container_bottom{
    display: flex;
    height: 250px;
    width: 98%;
    padding: 0 10px;
    justify-content: space-between;
}

.graphics{
    display: flex;
    height: 80%;
    justify-content: space-between;
    gap: .5em;
}

.container_graphics{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    justify-content: space-evenly;
}

.div_grapic{
    width: 100px;
}

.div_grapic p{
    width: 100%;
    margin: 0;
    font-size: .8rem;
}

.device_group{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 9em;
    width: 100%;
    gap: 3rem;
}

.icon_hgu{
    width: 55%;
}


.material-symbols-outlined {
  font-variation-settings:
  'FILL' 20,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24,
}

.icon span{
    font-size: 50px;
}

.icon{
    display: flex;
}

.icon p{
    margin: 7px 0;
}

.device_group div{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}