
.group{
    max-height: 27rem;
}

.span_icon, .span_icon div{
    display: flex;
    align-items: center;
}

.span_icon div{
    gap: 1rem;
    width: 40%;
    justify-content: space-between;
}

.main{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.atualization, .atualization span{
    display: flex;
}

.atualization span{
    align-items: center;
    gap: 1rem;
}

.atualization{
    flex-direction: column;
    align-items: flex-end;
    padding: 0 10px;
}

.atualization_tabel{
    display:  flex;
    align-items: center;
    gap: 1rem;
}

.content{
    display: flex;
    gap: 1rem;
    overflow: hidden;
}

.graphic_visualization{
    width: 50%;
}

