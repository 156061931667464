/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html,
body {
  margin: 0;
  height: 100%;
}

body {
  background-color: #f5f5f5;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font-family: "Roboto", sans-serif;
}

a {
  cursor: pointer;
}

label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success {
  color: #1d721c;
  background-color: #c2ffc6;
  border: 1px solid #63e12f;
  padding: 5px;
  border-radius: 5px;
}

.error {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
}

.logo_img {
  width: 50px;
  margin-right: 1em;
}

form {
  max-width: 40%;
  margin: 0 auto;
}

form input {
  border: 1px solid;
  border-color: #b9b9b980;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 200;
  margin-bottom: 1em;
  padding: 0.5em;
  width: 100%;
}

.button {
  border-radius: 109.804px;
  background: #461E5F;
  color: white;
}

.button:hover {
  background: #682c8d;
}

input::placeholder {
  color: #b9b9b9;
}

.divider {
  margin: 2em;
}

.card {
  background-color: #fff;
  margin: 0 2%;
  border-radius: 25px;
  box-shadow: 5px 5px 5px darkgray;
  margin-bottom: 1em;
}

.available,
.unavailable,
.warning {
  display: inline-flex;
  border-radius: 30px;
  min-width: 7em !important;
  max-width: 7em;
}

.available span,
.unavailable span,
.warning span {
  display: inline-flex;
  margin: 0 auto;
  font-weight: normal !important;
}

.available {
  background-color: #3be2b233;
  color: #16c190;
}

.unavailable {
  background-color: #ec515433;
  color: #ec5154;
}

.warning {
  background-color: #ffd60233;
  color: #ffc230;
}

.recaptcha {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 50% opacity black background */
  filter: grayscale(100%);
  /* Apply grayscale effect */
  z-index: 999;
  /* Adjust the z-index to ensure it's above everything except the header */
}

.spacing{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #461E5F 0%, #5065DA 100%);
  height: 10px;
  margin: 0;
  position: relative;
}