@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300..700&display=swap');

.main{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo{
    position: absolute;
    opacity: 0.3;
    bottom: 10px;
    right: 10px;
    width: 60%;
}

.container{
    width: 50%;
    margin: 0 0 40px;
}

.header{
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 75px 0 10px;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.header p{
    font-family: "Roboto", sans-serif;
}

.header_logo{
    display: flex;
    gap: 2em;
}

.header_logo img{
    width: 125px;
    height: 120px;
}

.header h1{
    font-family: "Signika", sans-serif;
    text-align: center;
    font-size: 3rem;
    margin: 20px 0;
}

.content{
    display: flex;
    justify-content: center;
    gap: 1em;
}

.select{
    width: 15%;
    border-radius: 8px;
    background-color: #fff;
}

.query_input{
    width: 45%;
    border-radius: 8px;
    background-color: #fff;
    color: #000;
}

.user_profile_container div{
    display: flex;
    flex-direction: column-reverse;
    padding: 7px;
}

.user_profile_container{
    padding: 5.5em 0 0em;
}