
.select{
    width: 30%;
    border-radius: 8px;
    background-color: #fff;
    height: 34px;
}

.query_input{
    width: 65%;
    border-radius: 8px;
    background-color: #fff;
    color: #000;
    height: 36px;
}

.container_filters{
    display: flex;
    gap: 1rem;
    justify-content: center;
    padding: 10px 20px;
    align-items: center;
}

.select_filters, .select_filters_date, .search_filter{
    height: 30px;
    width: 100%;
}

.search_filter{
    display: flex;
    align-items: center;
    gap: 1em;
}

.search_filter svg{
   width: 30px; 
   color: #fff;
}

.icon_search{
    background-color: #5065DA;
    padding: 8px;
    margin: 0 auto;
    border-radius: 50%;
    cursor: pointer;
}

.icon_search:hover{
    background-color: #394bb1;
}

.info_details_alert{
    display: flex;
    justify-content: space-between;
    padding: 6px 25px;
}

.type_alerts{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.type_alerts h1{
    font-size: 1rem;
}

.info_details_alert p, .info_details_alert h1{
    margin: 0;
}

.color_red, .color_yellow, .color_green{
    width: 20px;
    height: 20px;
}

.color_red{
    background-color: #FF0000;
}

.color_yellow{
    background-color: #FFC700;
}

.color_green{
    background-color: #00BA13;
}

.alerts_red, .alerts_yellow, .alerts_green{
    display: flex;
    gap: .4rem;
}

.atualization{
    display: flex;
    gap: .4rem;
    align-items: center;
}

.calendar{
    position: absolute;
    background-color: #fff; 
    border: 1px solid #cfcfcf;
}

.container_table table{
    width: 100%;
    padding: 0 20px;
}

.container_table tr{
    text-align: left;
}

.pagination{
    display: flex;
    align-items: center;
    gap: .5rem;
    justify-content: end;
    padding: 4px 0;
}

.buttonPage{
    margin: 0 3px;
    cursor: pointer;
}

.grave_line{
    background-color: #FF0000;
    width: 10px 4px;
}

.moderated_line{
    background-color: #FFC700;
    width: 10px 4px;
}

.soft_line{
    background-color: #00BA13;
    width: 10px 4px;
}

.container_table tr th, .container_table tr td{
    padding: 9.9px;
}

.container_table tr th, .container_table tr td:first{
    padding: 10px 4px;
}

.container_table tr th{
    color: #5065DA;
}