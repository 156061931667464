.caroussel{
    width: 97.3%;
    padding: 10px;
}

.card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.line_card{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cardButton{
    margin-bottom: 20px;
}