.main{
    background-color: #fff;
}

.container{
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.resultadoCertOK, .resultadoCertNOK{
    margin: 0;
}

.resultadoCertOK{
    color: green;
}
.resultadoCertNOK{
    color: rgb(160, 6, 6);
}
.aside{
    width: 28%;
    padding: 0 0 10 0px;
}

.topoServices{
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    gap: 1rem;
}

.caroussel{
    width: 97.3%;
    padding: 10px;
}


.textContainer{
    display: flex;
    text-align: justify;
    padding: 0 10px;
    flex-direction: column;
    align-items: flex-start;
}

.textContainer h1{
    font-size: 1.5rem;
    margin: 10px 0 0 0;
}

.cont{
    display: flex;
}

.mygrap{
    display: flex;
    justify-content: space-evenly;
    gap: 1em;
}

.line_card{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cardButton{
    margin-bottom: 20px;
}

.dateExec{
    color: #8c8c8d;
    width: 100%;
    text-align: end;
    font-size: .8rem;
    margin-top: -2px;
}

.tecLogin{
    display: flex;
}

.asideContent p{
    margin: 0;
}
.h1Atividade{
    font-size: .8rem;
    color: #8c8c8d;
}

.labelAtividade{
    font-size: .8rem;
    font-weight: bold;
    padding-left: 15px;
}

.hguImage{
    width: 100%;
    text-align: center;
    padding: 10px 0;
}

.appVersion{
    display: flex;
}

.spanVersion p{
    padding: 5px;
    background-color: #8c8c8da4;
    width: fit-content;
    border-radius: 20px;
}
.topoServices{
    font: "Roboto";
}

.topolyContent{
    margin-top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .3rem;
}

.topologyDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -20px;
    
}

.topologyDiv hr{
    margin: 0;
}

.auth, .no_auth, .bdm{
    margin: 2px;
    padding: 2px;
    font-size: .8rem;
    font-weight: 500;
    padding: 0 7px;
    width: fit-content;
    border-radius: 20px;
}

.auth{
    color: rgba(5, 97, 5, 0.644);
    background-color: rgba(66, 245, 66, 0.342);
}
.bdm{
    color: orange;
    background-color: rgba(255, 166, 0, 0.363);
}

.no_auth{
    color: red;
    background-color: rgba(255, 0, 0, 0.329);
}

.divService{
    display: flex;
    justify-content: space-between;
    height: 8rem;
    align-items: center;
}

.divService span{
    display: flex;
    gap: .2rem;
}

.divService .connectedServices{
    padding: 1px 4px;
    background-color: #8c8c8d;
    height: fit-content;
    border-radius: 50%;
}