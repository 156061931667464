.container{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
}

.main{
    display: flex;
    padding: 0  0 0 15px;
    overflow: hidden;
}

.aside_view{
    border-left: 1px solid #c9c9c9;
}

.aside_view header{
    display: flex;
    justify-content: flex-start;
    font-size: .7rem;
}

.aside_icon_close_aside{
    width: 20px;
    height: 45px;
    cursor: pointer;
    transition: 2s;
    color: #fff;
    background-color: #8083A3;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -21px;
    border-bottom-left-radius: 8px;
}

.aside_icon_close_aside span{
    transform: rotate(270deg);
}

.aside_view p, .aside_view h2{
    margin: 0;
}

.adise_connected_info{
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #fff;
    align-items: center;
    background-color: #00BA13;
    padding: 4px 0;
}

.image_hgu img{
    width: 35%;
}

.image_hgu{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9px;
}

.image_hgu p{
    font-weight: bold;
}

.aside_span_info{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3px;
}

.aside_span_info h1{
    margin: 0;
    font-size: .87rem;
}

.aside_span_info p{
    font-size: .9rem;
    font-weight: 500;
}
