.content {
  display: flex;
  justify-content: space-between;
  gap: 1em;
}

.select {
  width: 30%;
  border-radius: 8px;
  background-color: #fff;
  height: 36px;
}

.select2 {
  border-radius: 8px;
  background-color: #fff;
}

.query_input {
  width: 65%;
  border-radius: 8px;
  background-color: #fff;
  color: #000;
  height: 36px;
}

.query_input2 {
  border-radius: 8px;
  background-color: #fff;
  color: #000;
  width: 65%;
}

.container_filters {
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding: 30px 50px;
  align-items: center;
}

.select_filters,
.select_filters_date,
.search_filter {
  height: 30px;
  width: 100%;
}

.search_filter {
  display: flex;
  align-items: center;
  gap: 1em;
}

.search_filter svg {
  width: 30px;
  color: #fff;
}

.icon_search {
  background-color: #5065DA;
  padding: 8px;
  margin: 0 auto;
  border-radius: 50%;
  cursor: pointer;
}

.icon_search:hover {
  background-color: #394bb1;
}

.div_table {
  display: flex;
  justify-content: center;
  overflow-y: auto;
  margin-bottom: 30px;
}

.div_table table {
  width: 95%;
  text-align: left;

}

.div_table th {
  color: #5065DA;
  cursor: default;
}

.div_table img {
  width: 100px;
}

.div_table .item_list {
  cursor: pointer;
}

.div_table .item_list:hover {
  background-color: #0c0c0c24;
}

.div_table th {
  padding: 2rem 1rem;
}

.div_table td {
  color: #000;
  height: 100px;
}

.div_table .firmware {
  font-weight: bold;
}

.div_table .last_td {
  border-right: #00BA13 5px solid;
  height: 50px;
}

.div_table .last_td_red {
  border-right: #FF0000 5px solid;
  height: 50px;
}

.calendar {
  position: absolute;
  background-color: #fff;
  border: 1px solid #cfcfcf;
}

.error_div {
  position: absolute;
  top: 100px;
  left: 10px;
  background-color: #d32f2f;
  padding: 10px 15px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #fff
}

.error {
  margin: 0;
}